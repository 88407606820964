.altai-theme-admin #altai-admin-sidebar {
  width: 220px;
  background-color: #fff;
  box-shadow: 2px 0 10px -2px rgba(0, 0, 0, 0.3);
  z-index: 999;
}
.altai-theme-admin #altai-admin-sidebar #admin-sidebar-brand {
  background-color: #0074DC;
  color: #fff;
  font-size: 18px;
  padding: 17px;
}
.altai-theme-admin #altai-admin-sidebar #admin-sidebar-brand:hover {
  cursor: pointer;
}
.altai-theme-admin #altai-admin-sidebar-secondary {
  width: 200px;
  background-color: #0074DC;
  margin-right: 50px;
  color: #fff;
  box-shadow: 2px 0 10px -2px rgba(0, 0, 0, 0.3);
  padding: 10px 0;
}
.altai-theme-admin #altai-admin-sidebar-secondary ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.altai-theme-admin #altai-admin-sidebar-secondary ul li {
  padding: 10px 20px;
}
.altai-theme-admin #altai-admin-sidebar-secondary ul li:hover {
  color: rgba(255, 255, 255, 0.3);
}
.altai-theme-admin #altai-admin-sidebar-secondary ul li.heading {
  font-weight: bold;
}
.altai-theme-admin #altai-admin-sidebar-secondary ul li.heading:hover {
  color: inherit;
}
